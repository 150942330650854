import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins , sans-serif",
  },
  palette: {
    primary: {
      main: "#0B2F95",
    },
    secondary: {
      main: "#4863AF",  
    },
    warning: {
      main: "#F09630",
    },
    danger: {
      main: "#E74C3C",
    },
    success: {
      main: "#07A449",
    },
    background: {
      default: "#FFFFFF",
      secondary: "#000",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#EBF0FF",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: "10px !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          .pac-container {
            z-index: 1500 !important;
          }`,
    },
  },
});

export default theme;
