import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Loading from "../assets/Loading/loading";

//Auth Routes
const AuthHome = lazy(()=>import('../pages/Auth/AuthHome/Index')) 

const SignInWithOtp = lazy(()=>import("../pages/Auth/SignIn/SignIn"))
const SignInOtp = lazy(()=>import("../pages/Auth/SignIn/SignInOtp"))
const SignInWithEmail = lazy(()=>import("../pages/Auth/SignIn/SignInWithEmail/SignIn"))
const CompleteRegistration = lazy(()=>import("../pages/Auth/SignUp/CompleteRegistration"))
const RegisterSuccess = lazy(()=>import("../pages/Auth/SignUp/RegisterSuccess"))

//User Routes
const UserRegistration = lazy(() => import("../pages/User/Registration/Registration"))

const UserDelete = lazy(() => import("../pages/Auth/Delete/UserDelete"));


const SimpleRouter = memo(() => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/* auth */}
        <Route exact path="/auth" component={AuthHome} />
        <Route exact path="/auth/:userType/sign-in" component={SignInWithOtp} />
        <Route exact path="/auth/sing-in-otp" component={SignInOtp} />
        <Route exact path="/auth/sign-in-email" component={SignInWithEmail} />
        <Route exact path="/auth/:userType/sign-up" component={SignInWithOtp} />
        <Route exact path="/auth/sign-up-otp" component={SignInOtp} />
        <Route exact path="/auth/complete-registration" component={CompleteRegistration}/>
        <Route exact path="/auth/registration-success" component={RegisterSuccess}/>
        {/* user */}
        <Route exact path="/auth/registration" component={UserRegistration} />
        <Route exact path="/auth/user-delete" component={UserDelete} />

        {/* error */}
        {/* <Route exact path="/errors/error404" component={Error404} /> */}
      </Switch>
    </Suspense>
  );
});

SimpleRouter.displayName = "SimpleRouter";
export default SimpleRouter;
