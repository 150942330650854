import React, { memo } from "react";

//router
import { Switch, Redirect } from "react-router";

//layoutpages
import Default from "../layouts/default";
import Simple from "../layouts/simple";

//authmiddleware
import Authmiddleware from "./Authmiddleware";


const userRoutes = [
  { path: "/home", component: Default },
  { path: "/", component: () => <Redirect to="/home" /> },
  { path: "/admission", component: Default },
  { path: "/courses", component: Default },
  { path: "/more", component: Default },  
  { path: "/subscription-expired", component: Default },  
  { path: "/more/profile", component: Default },  
  { path: "/more/courses", component: Default }, 
  { path: "/more/add-course", component: Default }, 
  { path: "/more/edit-course/:courseID", component: Default }, 
  { path: "/more/coaches", component: Default },  
  { path: "/more/add-coach", component: Default },  
  { path: "/more/edit-coach/:coachID", component: Default },
  { path: "/more/ratings-reviews", component: Default },
  { path: "/more/transactions", component: Default },
  { path: "/more/subscriptions", component: Default },
  { path: "/more/new-subscription", component: Default },
  { path: "/more/subscription-success", component: Default },
  { path: "/more/subscription-failed", component: Default },
  { path: "/more/subscription-pending", component: Default },
  { path: "/more/students", component: Default },
  { path: "/more/import-student-data", component: Default },
  { path: "/more/add-payment", component: Default },
  { path: "/more/leads", component: Default },
  { path: "/admission/add-student", component: Default }, 
  { path: "/admission/:studentID/edit-student", component: Default }, 
  { path: "/course/:courseID/assign-student", component: Default },
  { path: "/admission/success", component: Default }, 
  { path: "/admission/student-details", component: Default },
  { path: "/course/:courseID/course-details", component: Default },
  { path: "/course/:courseID/mark-attendance", component: Default },
  { path: "/course/:courseID/student/:studentID/details", component: Default },
  { path: "/academy/:academyID/details", component: Default },
  { path: "/user/:academyID/create-review", component: Default },
  { path: "/user/:academyID/update-review", component: Default },
  { path: "/bookings", component: Default },
  { path: "/search", component: Default },
  { path: "/more/user-profile", component: Default },
  { path: "/enroll", component: Default },
  { path: "/enroll-success", component: Default },
  { path: "/payment-details", component: Default },

];

const authRoutes = [
  { path: "/auth", component: Simple },
  // { path: "/", component: () => <Redirect to="/auth" /> },
  { path: "/auth/:userType/sign-in", component: Simple },
  { path: "/auth/sing-in-otp", component: Simple },
  { path: "/auth/sign-in-email", component: Simple },
  { path: "/auth/:userType/sign-up", component: Simple },
  { path: "/auth/sign-up-otp", component: Simple },
  { path: "/auth/complete-registration", component: Simple },
  { path: "/auth/registration-success", component: Simple },
  { path: "/auth/:userType/registration", component: Simple },
  { path: "/auth/user-delete", component: Simple },

];

const IndexRouters = memo(() => {
  return (
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
