import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }} >
      <CircularProgress color="primary" sx={{ fontSize: 30 }} />
    </Box>
  );
};

export default Loading;
