import { Box } from "@mui/material";
import React from "react";
import theme from "../../utils/theme";
import headerBackground from "../../assets/header-bg.png";
import homeLogo from "../../assets/logo/home-logo.svg";
import notificationIcon from "../../assets/utils/notifications.svg";

function Header() {
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex:2,
          width: "100%",
          height: "100px",
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(${headerBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 3,
        }}
      >
        <img src={homeLogo} alt="logo" width={180} />
        <img src={notificationIcon} alt="logo" width={16} />
      </Box>
    </React.Fragment>
  );
}

export default React.memo(Header);
