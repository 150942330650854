import { createContext, useState } from "react";

export const variableContext = createContext();

const Variable = ({ children }) => {
  const [mobile, setMobile] = useState("");
  const [userType, setUserType] = useState('');
  

  return (
    <variableContext.Provider
      value={{
        mobile,
        setMobile,
        userType,
        setUserType
      }}
    >
      {children}
    </variableContext.Provider>
  );
};

export default Variable;
