import { memo, Fragment } from "react";
//default
import DefaultRouter from "../router/default-router";
import Header from "../components/TopBar/Index";
import BottomNavbar from "../components/BottomNav/BottomNav";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const styles ={
  px: 2.5,
  py: 1.5,
  mb: 12,
  mt: 13,
  overflow: "auto",
}

const Default = memo(() => {
  const location = useLocation();
  const loadSection = (location) => {
    if (location.pathname.includes('/more/')) {
      return false
    } else if (location.pathname.includes('/add-student')) {
      return false
    } else if (location.pathname.includes('/edit-student')) {
      return false
    } else if (location.pathname.includes('/student/')) {
      return false
    } else if (location.pathname.includes('/course-details')) {
      return false
    } else if (location.pathname.includes('/mark-attendance')) {
      return false
    } else if (location.pathname.includes('/assign-student')) {
      return false
    } else if (location.pathname.includes('/academy/')) {
      return false
    } else if (location.pathname.includes('/search')) {
      return false
    } else if (location.pathname.includes('/enroll')) {
      return false
    } else if (location.pathname.includes('/payment-details')) {
      return false
    } else if (location.pathname.includes('/admission/student-details')) {
      return false
    } else if (location.pathname.includes('/create-review')) {
      return false
    } else if (location.pathname.includes('/update-review')) {
      return false
    } else {
      return true
    }
  };

  return (
    <Fragment>
      {loadSection(location)&&<Header />}
      <Box sx={loadSection(location)?styles:{}}>
        <DefaultRouter />
      </Box>
      {loadSection(location)&&<BottomNavbar />}
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
