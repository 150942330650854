import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { QueryClientProvider, QueryClient } from "react-query";
import Variable from "./context/VariableContext";
import { SnackbarProvider } from 'notistack';

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry:2,
      },
    },
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
  <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
    <CssBaseline>
      <QueryClientProvider client={queryClient}>
        <Variable>
          <App />
        </Variable>
      </QueryClientProvider>
    </CssBaseline>
    </SnackbarProvider>
  </ThemeProvider>
);

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     alert("New version available!  Ready to update?");
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: "SKIP_WAITING" });
//     }
//     window.location.reload();
//   },
// });
// reportWebVitals();
