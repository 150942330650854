import * as React from "react";
import IndexRouters from "./router/index";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import SplashScreen from './assets/Splash Screen.svg'

function App() {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);



  //PWA Install Button

  // window.addEventListener("beforeinstallprompt", async (e) => {
  //   const relatedApps = await navigator.getInstalledRelatedApps();
  //   const PWAisInstalled = relatedApps.length > 0;
  //   if (!PWAisInstalled) {
  //     let deferredPrompt = e;

  //     const installButton = document.createElement("button");
  //     installButton.textContent = "Continue To App";
  //     installButton.style.position = "fixed";
  //     installButton.style.bottom = "0px";
  //     installButton.style.padding = "10px 20px";
  //     installButton.style.color = "white";
  //     installButton.style.border = "none";
  //     installButton.style.borderRadius = "5px";
  //     installButton.style.cursor = "pointer";
  //     installButton.style.width = "100%";
  //     installButton.style.height = "50px";
  //     installButton.style.backgroundColor = "#FA5C1F";
  //     installButton.style.fontSize = "14px";
  //     installButton.style.fontWeight = "bold";
  //     installButton.style.zIndex = "9999";

  //     installButton.addEventListener("click", () => {
  //       deferredPrompt.prompt();

  //       deferredPrompt.userChoice.then((choiceResult) => {
  //         if (choiceResult.outcome === "accepted") {
  //           console.log("App installed");
  //         } else {
  //           console.log("App installation declined");
  //         }

  //         installButton.style.display = "none";
  //       });
  //     });

  //     document.body.appendChild(installButton);
  //   }
  // });

  if (loading) {
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'90vh',width:'100%'}}>
          <img
            src={SplashScreen}
            alt="Academy"
          />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Router>
        <IndexRouters />
      </Router>
    </React.Fragment>
  );
}

export default App;
