import { memo, Fragment } from "react";

//SimpleRouter
import SimpleRouter from "../router/simple-router";


const Simple = memo((props) => {
  return (
    <Fragment>
        <SimpleRouter />
    </Fragment>
  );
});

Simple.displayName = "Simple";
export default Simple;
