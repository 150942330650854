import React, { useEffect, useState } from "react";
import "./bottomNav.css";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import theme from "../../utils/theme";
import homeIcon from "../../assets/utils/Navbar-icons/home.svg";
import activeHomeIcon from "../../assets/utils/Navbar-icons/activeHome.svg";
import bookingIcon from "../../assets/utils/Navbar-icons/bookings.svg"
import activeBookingIcon from "../../assets/utils/Navbar-icons/activeBooking.svg"
import studentIcon from "../../assets/utils/Navbar-icons/student.svg";
import activeStudentIcon from "../../assets/utils/Navbar-icons/activeStudent.svg";
import moreIcon from "../../assets/utils/Navbar-icons/more.svg";
import activeMoreIcon from "../../assets/utils/Navbar-icons/activeMore.svg";
import searchIcon from "../../assets/utils/Navbar-icons/search.svg";

const BottomNavbar = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const [data, setData] = useState([
    {
      icon: homeIcon,
      activeIcon: activeHomeIcon,
      path: "/home",
      title: "Home",
    },
    {
      icon: bookingIcon,
      activeIcon: activeBookingIcon,
      path: "/admission",
      title: 'Admission'
    },
    {
      icon: studentIcon,
      activeIcon: activeStudentIcon,
      path: "/courses",
      title: 'Course'
    },
    {
      icon: moreIcon,
      activeIcon: activeMoreIcon,
      path: "/more",
      title: 'More'
    },
  ]);

  useEffect(() => {
    if (user?.user_type === "user") {
      setData([
        {
          icon: homeIcon,
          activeIcon: activeHomeIcon,
          path: "/home",
          title: "Home",
        },
        {
          icon: searchIcon,
          activeIcon: activeHomeIcon,
          path: "/search",
          title: 'Search'
        },
        {
          icon: bookingIcon,
          activeIcon: activeStudentIcon,
          path: "/bookings",
          title: 'Bookings'
        },
        {
          icon: moreIcon,
          activeIcon: activeMoreIcon,
          path: "/more",
          title: 'More'
        },
      ])
    }
  }, [user?.user_type])

  const handleClick = (value) => {
    history.push(value);
  };

  return (
    <div className="bottom-nav">
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: .5,
            cursor: "pointer",
          }}
          onClick={() => handleClick(item.path)}
        >
          <Box sx={{ backgroundColor: location.pathname === item.path ? '#0B2F95' : '', borderRadius: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', px: 2, py: .5 }}>
            <img style={{ py: 0 }} src={location.pathname === item.path ? item?.activeIcon : item.icon} alt="icon" />
          </Box>
          <Typography variant="caption" color={theme.palette.primary.main} sx={{ fontSize: "12px", fontWeight: 600 }}>
            {item.title}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default BottomNavbar;
